import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const Account = () => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState(new Array(6).fill(''));
    const [originalEmail, setOriginalEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [step, setStep] = useState(1); // 1 for email, 2 for verification code, 3 for profile
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);

    const [updatingEmail, setUpdatingEmail] = useState(false);
    const location = useLocation();
    const inputsRef = useRef([]);


    // Email provided in URL
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailFromURL = queryParams.get('email');

        if (emailFromURL) {
            setEmail(emailFromURL); // Set the email from URL
            checkCustomerEmail(emailFromURL); // Check email with backend
        }
    }, [location.search]);

    const checkCustomerEmail = async (email) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_HOST}/check-customer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'customer-api-key': 'API3ed4'
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (data.exists) {
                if (data.isPregeneratedEmail) {
                    // If it's an Intel email, go directly to subscription details
                    setOriginalEmail(email);
                    fetchSubscriptionDetails(email);
                    setStep(3); // Skip MFA, go to edit subscription
                } else {
                    // Normal email, proceed to MFA
                    setOriginalEmail(email); // Set the original email
                    setStep(1); // Go to MFA step
                }
            } else {
                setError('Customer does not exist');
            }
        } catch (err) {
            console.error('Error checking customer email:', err);
            setError('Failed to check customer email.');
        }
    };

    const openCustomerPortal = () => {
        fetch(`${process.env.REACT_APP_NODE_BACKEND_HOST}/create-customer-portal-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'api-key': 'API1qaz2wsx'
            },
            body: JSON.stringify({ customerId: subscriptionDetails.customerId }), // Use customerId here
        })
            .then(response => response.json())
            .then(data => {
                window.open(data.url, '_blank'); // Open the customer portal in a new tab
            })
            .catch((error) => {
                console.error('Error opening customer portal:', error);
            });
    };

    // Helper function to send the verification code (used by both form and URL check)
    const sendVerificationCode = async (email) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_HOST}/send-verification-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'customer-api-key': 'API3ed4'
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Failed to send verification code.');
            }
        } catch (err) {
            console.error('Error sending verification code:', err);
            setError('Failed to send verification code.');
        }
    };


    const handleEmailSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setError('Invalid Email');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            await sendVerificationCode(email);

            if (!updatingEmail) {
                setOriginalEmail(email);
            }

            setStep(2);
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to send verification code.');
        } finally {
            setLoading(false);
        }
    };

    const handleCodeChange = (index, value) => {
        if (/^\d*$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (value && index < 5) {
                inputsRef.current[index + 1].focus();
            } else if (!value && index > 0) {
                inputsRef.current[index - 1].focus();
            }
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text').slice(0, 6).split('');
        const newCode = [...code];

        pasteData.forEach((value, index) => {
            newCode[index] = value;
            if (inputsRef.current[index]) {
                inputsRef.current[index].value = value;
            }
        });

        setCode(newCode);
    };

    const handleCodeSubmit = async (e) => {
        e.preventDefault();

        const fullCode = code.join('');
        if (fullCode.length !== 6) {
            setError('Please enter the full 6-digit code.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_HOST}/verify-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'customer-api-key': 'API3ed4'
                },
                body: JSON.stringify({ email, code: fullCode }),
            });

            if (!response.ok) {
                throw new Error('Verification failed.');
            }

            // Step 2: If updating the email, check if the new email is already in use
            if (updatingEmail) {
                const customerCheckResponse = await fetch(`${process.env.REACT_APP_NODE_BACKEND_HOST}/check-customer`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'customer-api-key': 'API3ed4'
                    },
                    body: JSON.stringify({ email }), // Check if the new email exists
                });

                const customerCheckData = await customerCheckResponse.json();

                if (customerCheckData.exists) {
                    throw new Error('This email is already in use. Please use a different email.');
                }

                // Step 3: If the new email doesn't exist, proceed to update it in Stripe
                const updateResponse = await fetch(`${process.env.REACT_APP_NODE_BACKEND_HOST}/update-email`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: originalEmail, newEmail: email }), // Pass original and new email
                });

                if (!updateResponse.ok) {
                    throw new Error('Failed to update email on Stripe.');
                }

                setUpdatingEmail(false); // Reset email update flag
                setStep(4); // Move to email update success step
            } else {
                fetchSubscriptionDetails(email); // Fetch subscription details
            }
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to verify code.');
        } finally {
            setLoading(false);
        }
    };

    const fetchSubscriptionDetails = async (userEmail) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_HOST}/get-subscription-details`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': 'API1qaz2wsx'
                },
                body: JSON.stringify({ email: userEmail }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch subscription details.');
            }

            const data = await response.json();

            //console.log('Subscription details fetched:', data);  // Log the fetched data

            // Ensure customerId is included in the subscription details
            setSubscriptionDetails({
                ...data,
                customerId: data.customerId,  // Ensure customerId is included in the response
            });

            if (!data.customerId) {
                throw new Error('Customer ID is missing in the fetched subscription details.');
            }

            setStep(3);  // Move to the profile step
        } catch (err) {
            console.error('Error:', err);
            setError('Account not found.');
        } finally {
            setLoading(false);
        }
    };
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const renderStatusIndicator = () => {
        let statusText;
        let dotColor;

        const isTrial = subscriptionDetails.trialEnd && subscriptionDetails.trialEnd > Date.now() / 1000;

        if (isTrial) {
            statusText = 'Trial';
            dotColor = '#2BB86C'; // Green for trial
        } else if (subscriptionDetails.status === 'active') {
            statusText = 'Active';
            dotColor = '#2BB86C'; // Green for active
        } else {
            statusText = 'Expired';
            dotColor = '#FF0000'; // Red for expired
        }

        return (
            <div style={styles.statusIndicator}>
                <span style={{ ...styles.dot, backgroundColor: dotColor }}></span>
                <span>{statusText}</span>
            </div>
        );
    };
    const renderDetails = () => {
        const isTrial = subscriptionDetails.trialEnd && subscriptionDetails.trialEnd > Date.now() / 1000;

        return (
            <div style={styles.details}>
                {isTrial ? (
                    <>Expiring: {formatDate(subscriptionDetails.trialEnd)}</>  // Show expiring date for trial
                ) : subscriptionDetails.status === 'active' ? (
                    subscriptionDetails.autoRenew === 'Off' ? (
                        <>Expiring: {formatDate(subscriptionDetails.currentPeriodEnd)}</>  // Show expiring if auto-renew is off
                    ) : (
                        <>Renewing: {formatDate(subscriptionDetails.currentPeriodEnd)}</>  // Show renewing if auto-renew is on
                    )
                ) : (
                    <>Expired: {formatDate(subscriptionDetails.currentPeriodEnd)}</>  // Show expired for inactive subscriptions
                )}
            </div>
        );
    };
    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString();
    };

    const styles = {
        container: {
            margin: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Inter, sans-serif',
        },
        contentBox: {
            padding: '30px 48px',
            backgroundColor: 'transparent',
            color: 'white',
            maxWidth: step === 3 ? '600px' : '500px',
        },
        logoImage: {
            width: '250px',
            height: 'auto',
        },
        title: {
            fontSize: step === 3 ? '16px' : '28px', // Adjusted to 16px for consistency
            color: step === 3 ? '#A2AAB6' : 'white',
            marginTop: step === 3 ? '0px' : '30px',
            padding: 0, // Remove default padding
            display: 'flex',
            alignItems: 'center', // Center vertically with statusIndicator
        },
        subtext: {
            marginTop: '30px',
            lineHeight: '1.6',
            fontSize: '16px',
            color: '#a8b3c2ff',
        },
        input: {
            width: '100%',
            marginTop: '30px',
            padding: '12px',
            fontSize: '16px',
            border: '1px solid #4e5a68ff',
            backgroundColor: '#242b33ff',
            color: 'white',
            borderRadius: '4px',
            boxSizing: 'border-box',
            outline: 'none',
            transition: 'border-color 0.3s ease',
        },
        inputBox: {
            width: '50px',
            height: '70px',
            margin: '10px',
            padding: '12px',
            fontSize: '24px',
            textAlign: 'center',
            border: '1px solid #4e5a68ff',
            backgroundColor: '#242b33ff',
            color: 'white',
            borderRadius: '4px',
            outline: 'none',
            transition: 'border-color 0.3s ease',
        },
        inputBoxFilled: {
            borderColor: '#3769e4ff',
        },
        button: {
            width: '100%',
            marginTop: '20px',
            padding: '12px',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#3769e4ff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        error: {
            color: 'red',
            marginTop: '10px',
            fontSize: '14px',
        },
        codeInputContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
        },
        leftSide: {
            width: '100%',
            color: '#A2AAB6',
            marginTop: '30px',
        },
        subscriptionTitle: {
            fontSize: '16px',
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: '14px',
            paddingBottom: '14px',
            fontWeight: 'bold',
        },
        details: {
            fontSize: '14px',
            color: '#A2AAB6',
            marginTop: '10px',
        },
        autoRenew: {
            fontSize: '14px',
            color: '#A2AAB6',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            marginTop: '10px',
        },
        subscriptionHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '10px',
            borderBottom: step === 3 ? '1px solid #4e5a68ff' : 'none',
        },
        statusIndicator: {
            display: 'flex',
            alignItems: 'center',
        },
        statusText: {
            marginLeft: '5px',
            fontSize: '16px',
            color: '#A2AAB6',
        },
        dot: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginRight: '5px',
        },
        updateEmailText: {
            color: 'white',
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '16px',
            marginTop: '24px',
            display: 'block', // Centers the text
            textAlign: 'center', // Ensures the text is centered
        },


    };

    return (
        <div style={styles.container}>
            <div style={styles.contentBox}>
                <img src={`${process.env.PUBLIC_URL}/logo-generate-reverse.png`} alt="Logo" style={styles.logoImage} />
                {step === 1 && !updatingEmail && (
                    <>
                        <h1 style={styles.title}>Login to Your Account</h1>
                        <p style={styles.subtext}>
                            Enter your email address to receive a verification code. You will use this code to access your account.
                        </p>
                        <form onSubmit={handleEmailSubmit}>
                            <input
                                type="text"
                                style={styles.input}
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {error && <p style={styles.error}>{error}</p>}
                            <button
                                style={styles.button}
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? 'Processing...' : 'Send Verification Code'}
                            </button>
                        </form>
                    </>
                )}

                {step === 1 && updatingEmail && (
                    <>
                        <h1 style={styles.title}>Update Your Email Address</h1>
                        <p style={styles.subtext}>Enter a new email address below and we will send you a verification code to confirm the update.</p>
                        <form onSubmit={handleEmailSubmit}>
                            <input
                                type="email"
                                style={styles.input}
                                placeholder="Enter your new email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            {error && <p style={styles.error}>{error}</p>}
                            <button style={styles.button} type="submit" disabled={loading}>
                                {loading ? 'Processing...' : 'Send Verification Code'}
                            </button>
                        </form>
                    </>
                )}

                {step === 2 && (
                    <>
                        <h1 style={styles.title}>Enter Your Verification Code</h1>
                        <p style={styles.subtext}>
                            We have sent a 6-digit verification code to your email. Please enter it below to log in.
                        </p>
                        <form onSubmit={handleCodeSubmit}>
                            <div style={styles.codeInputContainer}>
                                {code.map((digit, index) => (
                                    <input
                                        key={index}
                                        ref={(el) => (inputsRef.current[index] = el)}
                                        type="text"
                                        style={{
                                            ...styles.inputBox,
                                            ...(digit ? styles.inputBoxFilled : {}),
                                        }}
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handleCodeChange(index, e.target.value)}
                                        onPaste={handlePaste}
                                    />
                                ))}
                            </div>
                            {error && <p style={styles.error}>{error}</p>}
                            <button
                                style={styles.button}
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? 'Verifying...' : 'Verify and Login'}
                            </button>
                        </form>
                    </>
                )}

                {step === 3 && subscriptionDetails && (
                    <div style={styles.leftSide}>
                        <div style={styles.subscriptionHeader}>
                            <h1 style={styles.title}>My Subscription</h1>
                            {renderStatusIndicator()}
                        </div>
                        <div style={styles.subscriptionTitle}>
                            <span>
                                {subscriptionDetails.billingInterval === 'year'
                                    ? 'Generate AI Manager Annual Subscription'
                                    : 'Generate AI Manager Monthly Subscription'}
                            </span>
                            <span style={{ marginLeft: '50px', color: '#A2AAB6' }}>
                                ${subscriptionDetails.price}/{subscriptionDetails.billingInterval === 'year' ? 'yr' : 'mo'}
                            </span>
                        </div>
                        {subscriptionDetails.licenseKey && (
                            <div style={{ ...styles.details, color: 'white' }}>
                                License Key: {subscriptionDetails.licenseKey}
                            </div>
                        )}
                        {subscriptionDetails.email && (
                            <div style={{ ...styles.details }}>
                                Email: {subscriptionDetails.email}
                            </div>
                        )}
                        {renderDetails()}
                        <div style={styles.autoRenew}>
                            Auto-renew: {subscriptionDetails.autoRenew}
                        </div>
                        <button
                            style={styles.button}
                            onClick={openCustomerPortal}
                        >
                            Edit Subscription
                        </button>
                        {/* New "Update Email Address" button */}
                        <span
                            style={styles.updateEmailText}
                            onClick={() => {
                                setUpdatingEmail(true);
                                setCode(new Array(6).fill(''));
                                setStep(1); // Reset step to start email update flow
                            }}
                        >
                            Update Your Email Address
                        </span>
                    </div>
                )}

                {/* Step 4: Email Updated Success */}
                {step === 4 && (
                    <>
                        <h1 style={styles.title}>Your Email Has Been Updated!</h1>
                        <button
                            style={styles.button}
                            onClick={openCustomerPortal}
                        >
                            Edit Subscription
                        </button>
                    </>
                )}

            </div>
        </div>
    );
};

export default Account;

import React from 'react';

const EulaPage = () => {
  return (
    <div className="eula-container">
      <h1 className="eula-title">Generate.ai Business and End User Terms</h1>
      <p className="eula-date">Welcome to Generate.AI! Last Updated: August 9, 2024</p>

      <p>
        This Business and End-User Terms Agreement (“Agreement”) is made by and between Iterate Studio Inc. d/b/a Iterate.ai, a Delaware corporation having an address of 10766 Rainribbon Road, Highlands Ranch, Colorado 80126, USA, (“Company”), and the entity or individual identified through the account or signature below (“You”). By accessing or using our Application, You agree to be bound by this Agreement. If You are entering into this Agreement on behalf of an organization as a user, You represent that you have the authority to bind that organization.
      </p>

      <p>
        Please read this Agreement carefully by clicking the "I Accept" button, or otherwise signing separately indicating Your acceptance by Your signature, you agree to be bound by the terms and conditions of this Agreement before subscribing to or using the Generate.ai platform.
      </p>

      <h2>Interpretation and Definitions:</h2>
      <h3>Interpretation.</h3>
      <p>
        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </p>
      <h3>Definitions.</h3>
      <p>
        For the purposes of this Agreement:
      </p>
      <ul>
        <li>
          <strong>Application</strong> refers to the Generate.ai app and features licensed to you via the Generate.ai platform, whether subscribed directly or accessed through the Statement of Work (SoW), including its secure AI Manager capabilities and any associated software, solutions, and any updates or modifications provided by the Company.
        </li>
        <li>
          <strong>Generate.ai</strong> refers to Iterate’s secure AI Manager platform, including its modular and flexible architecture that allows deployment of local SLMs (Small Language Models) and LLMs (Large Language Models) across various hardware configurations, and the software on which it is based (“Generate.ai Core”), along with licensed components, updates, and enhancements powered by Iterate.ai and supported by the Company’s Interplay Platform.
        </li>
        <li>
          <strong>Third-Party Services</strong> means any services or content (including data, information, applications, and other products services) provided by a third-party that may be displayed, included or made available by the Application.
        </li>
      </ul>

      <h2>Services:</h2>
      <h3>Scope.</h3>
      <p>
        In exchange of the fees detailed in our pricing page or a SOW (all fees payable in US Dollars and will include price adjustments, correction of errors) and for the duration stated in an applicable subscription package or SoW between You and the Company, the Company grants You an end-user revocable, non-exclusive, non-transferable, limited license to use the Application strictly in accordance with the terms of this Agreement and SoW. Fees for the use of subscribed Application by You through Your account or SoW, exclude taxes, which we will charge as required by law, based on Your account address. To dispute any fee, contact <a href="mailto:accounting@iterate.ai">accounting@iterate.ai</a> within 30 days of issuance. Overdue, undisputed amounts may incur a 1.5% monthly finance charge, and subscription to the Application may be suspended after written notice of late payment. The subscribed Application provided is not sold to You, and You may only use the Application as permitted by the Company’s terms and conditions and subject to the agreed subscription terms.
      </p>

      <h3>Restrictions.</h3>
      <p>
        You agree not to, and will not permit others (including Your partners) to:
      </p>
      <ul>
        <li>Attempt to duplicate, share, license, reverse engineer, decompile, disassemble, or modify the Generate.ai Platform or its components;</li>
        <li>Interfere with the operation of the Generate.ai Platform or the hardware and network used to operate it;</li>
        <li>Misuse, modify, translate, localize, adapt, rent, lease, loan, create derivative works of, or file any patent based on the Generate.ai Platform;</li>
        <li>License, sell, rent, lease, assign, distribute, transmit, host, or outsource the Application to any third party;</li>
        <li>Remove, alter, or obscure any proprietary notices of the Company or its licensors;</li>
        <li>Use the Application in a manner that violates applicable laws or our policies;</li>
        <li>Use the Application in a way that infringes third-party rights;</li>
        <li>Share personal data of children under 13 without proper consent;</li>
        <li>Use our Application for processing Protected Health Information unless you have signed a Healthcare Addendum with us.</li>
      </ul>

      <h3>Account Responsibilities.</h3>
      <p>
        You must ensure that Your account information is accurate and current. You are responsible for all activities that occur under Your account, including those by any end users or accounts provisioned under Your account. You must not share or sell Your account credentials and must promptly report any unauthorized access to us.
      </p>

      <h2>Updates:</h2>
      <p>
        We may update this Agreement and related policies with reasonable notice, including posting updates on our website. If an update materially affects Your rights or obligations, we will give at least 30 days' notice, unless the change is legally required, in which case we’ll notify you as soon as possible. Other updates will be effective on the posting date. Your continued use of the Application after an update is effective constitutes acceptance. If you disagree, we may have to terminate the Agreement.
      </p>

      <h3>Exceptions to Updates:</h3>
      <p>
        Updates will not apply to: (a) Disputes arising before the update; or (b) Subscribed Application or SoW signed before the update notice, unless the update is legally necessary or relates to a new Applications or feature used after the subscribed Application. Updates will not affect agreed terms in the Statement of Work (SoW).
      </p>

      <h2>Security and Privacy Obligations:</h2>
      <p>
        Company will maintain a security program to protect the Application and Customer Content, addressing risks through regular assessments, enforcing security policies, and minimizing threats. This includes measures like VPNs, multi-factor authentication, least-privilege access, and incident response processes. Additionally, if you process personal data using our Services, you must provide proper privacy notices, obtain necessary consents, comply with applicable laws, and execute our Data Processing Addendum if required.
      </p>

      <h2>Intellectual Property:</h2>
      <p>
        The Generate.ai Platform, including all copyrights, patents, trademarks, trade secrets, and other intellectual property rights, remains the sole property of the Company. The Company is not obligated to indemnify or defend You with respect to any third-party claims related to the Generate.ai Platform. You and Your end users provide input to and receive output from our Application ("Customer Content"). You own both Your input and the resulting output, which we assign to you. We use Customer Content only to effectuate Your use of the Application, comply with laws, and enforce policies, and not for service improvement. You must have the rights to Your input and are responsible for the output's accuracy and appropriateness. AI-generated output may not be unique, and responses for other users are not considered Your output. Our assignment of output does not include other users' responses or third-party content.
      </p>

      <h2>Confidential Information:</h2>
      <p>
        During the Term and for one (1) year after the Term, each party must maintain the Confidential Information of the other party as confidential. Confidential Information includes the terms of this Agreement, all personal information, Generate.ai Platform data, and all proprietary or technical knowledge disclosed by one party to the other. Exceptions to confidentiality include information that: (i) Was publicly known before receipt, or becomes publicly known through no fault of the Recipient; (ii) Was already in the Recipient's possession prior to receipt; (iii) Was received from a third party without confidentiality obligations; (iv) Consists solely of generalized data processing ideas, concepts, or techniques.
      </p>

      <h2>Third-Party Services:</h2>
      <p>
        The Application may include or link to third-party content or services. The Company is not responsible for the accuracy, completeness, or legality of such third-party services. You access and use third-party services at Your own risk and must comply with their terms of use.
      </p>

      <h2>Term and Termination:</h2>
      <p>
        This Agreement remains in effect (i) upon acceptance or first use of the Application and continue until terminated. Subscriptions to the subscribed pricing will auto-renew unless notice is given at least 30 days before renewal or (ii) for terms specified in the SoW. The Company may terminate this Agreement if You fail to comply with its terms, and will notify You within 15 days. If You terminate, the rights to use the Application cease immediately, and You must cease all use. 
      </p>

      <h2>Limitation of Liability:</h2>
      <p>
        To the fullest extent permitted by law, in no event will the Company be liable for any indirect, special, incidental, consequential, punitive, or other damages, including loss of profits, revenue, data, use, goodwill, or other intangible losses, arising from Your use of the Application, any unauthorized access or use of Your account, or any content or conduct of any third party. The Company’s liability for all claims arising from this Agreement shall not exceed the total fees paid by You to the Company in the 12 months preceding the claim.
      </p>

      <h2>Indemnification:</h2>
      <p>
        You agree to indemnify, defend, and hold harmless the Company, its affiliates, officers, directors, and employees from any claims, damages, losses, liabilities, costs, or expenses (including reasonable attorneys' fees) arising from (a) Your use of the Application, (b) Your violation of this Agreement, or (c) Your violation of any third-party rights.
      </p>

      <h2>Governing Law:</h2>
      <p>
        This Agreement shall be governed by and construed in accordance with the laws of the State of Colorado, without regard to its conflict of laws principles. Any disputes arising under this Agreement shall be brought exclusively in the state or federal courts located in Denver, Colorado, and You consent to the jurisdiction of such courts.
      </p>

      <h2>General Provisions:</h2>
      <ul>
        <li><strong>Entire Agreement:</strong> This Agreement constitutes the entire agreement between the parties regarding the subject matter hereof and supersedes all prior agreements and understandings.</li>
        <li><strong>Amendments:</strong> This Agreement may not be amended except in writing signed by both parties.</li>
        <li><strong>Severability:</strong> If any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</li>
        <li><strong>Waiver:</strong> No waiver of any breach of this Agreement shall be deemed a waiver of any preceding or subsequent breach.</li>
      </ul>

      <h2>Contact Information:</h2>
      <p>
        If you have any questions about this Agreement, please contact us at <a href="mailto:support@iterate.ai">support@iterate.ai</a>.
      </p>

      <p>
        By using our Application, you acknowledge that you have read, understood, and agree to be bound by this Agreement.
      </p>
<br />
        <br />

      <style jsx>{`
        .eula-container {
          padding: 20px;
          max-width: 800px;
          margin: auto;
          font-family: Arial, sans-serif;
          line-height: 1.6;
        }

        .eula-title {
          text-align: center;
          font-size: 2em;
          margin-bottom: 20px;
        }

        .eula-date {
          text-align: center;
          font-size: 1em;
          color: #666;
          margin-bottom: 20px;
        }

        h2 {
          margin-top: 30px;
          margin-bottom: 10px;
          font-size: 1.5em;
        }

        h3 {
          margin-top: 20px;
          margin-bottom: 10px;
          font-size: 1.2em;
        }

        ul {
          margin-left: 20px;
        }

        p {
          margin-bottom: 15px;
        }
      `}</style>
    </div>
  );
};

export default EulaPage;

import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
const CUSTOMER_API_KEY = "API3ed4";

const SuccessPage = () => {
    const [licenseKey, setLicenseKey] = useState(null);
    const [copied, setCopied] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const sessionId = urlParams.get('session_id');

        if (sessionId) {
            verifySessionAndFetchLicense(sessionId);
        } else {
            //console.log('No session ID found, redirecting to trial page');
            navigate('/subscribe');
        }
    }, [location.search, navigate]);

    const verifySessionAndFetchLicense = async (sessionId, retries = 10) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_HOST}/verify-session`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'customer-api-key': CUSTOMER_API_KEY,
                },
                body: JSON.stringify({ sessionId }),
            });
    
            if (!response.ok) {
                throw new Error(`Verification failed: ${response.statusText}`);
            }
    
            const data = await response.json();
            if (data.licenseKey) {
                setLicenseKey(data.licenseKey);
            } else {
                // If no license key found and retries left, try again after a short delay
                if (retries > 0) {
                    //console.log(`License key not found, retrying... (${3 - retries + 1}/3)`);
                    setTimeout(() => verifySessionAndFetchLicense(sessionId, retries - 1), 2000);
                } else {
                    setError('License key could not be retrieved.');
                }
            }
        } catch (error) {
            console.error('Error during session verification:', error);
            setError('Request timed out.');
        } finally {
            setLoading(false);
        }
    };
    

    const handleCopyToClipboard = () => {
        if (licenseKey) {
            navigator.clipboard.writeText(licenseKey).then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            }).catch(err => {
                console.error('Failed to copy license key:', err);
            });
        }
    };

    const styles = {
        container: {
            margin: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Calibri, sans-serif',
        },
        contentBox: {
            padding: '30px 48px',
            backgroundColor: 'transparent',
            color: 'white',
            maxWidth: '500px',
        },
        title: {
            marginTop: '30px',
            fontSize: '28px',
        },
        message: {
            marginTop: '20px',
            fontSize: '16px',
            color: 'white',
        },
        grayBox: {
            marginTop: '30px',
            padding: '20px',
            backgroundColor: '#303946ff',
            borderRadius: '8px',
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 'bold',
            fontSize: '16px',
        },
        copyIcon: {
            cursor: 'pointer',
            marginLeft: '10px',
            width: '18px',
            height: '18px',
        },
        copiedText: {
            marginLeft: '10px',
            fontSize: '14px',
            color: '#4e5a68ff',
        },
        error: {
            color: 'red',
            marginTop: '10px',
            fontSize: '14px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.contentBox}>
                <h1 style={styles.title}>Thank You for Subscribing!</h1>
                <p style={styles.message}>
                    {loading ? 'Loading your license key...' : 'Return to Generate and enter the key below. Your license has been sent to your email.'}
                </p>

                {licenseKey && (
                    <div style={styles.grayBox}>
                        {licenseKey}
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {!copied && (
                                <img
                                    src={`${process.env.PUBLIC_URL}/clipboard.png`}
                                    alt="Copy to clipboard"
                                    style={styles.copyIcon}
                                    onClick={handleCopyToClipboard}
                                />
                            )}
                            {copied && <span style={styles.copiedText}>Copied!</span>}
                        </span>
                    </div>
                )}
                {error && <p style={styles.error}>{error}</p>}
            </div>
        </div>
    );
};

export default SuccessPage;
